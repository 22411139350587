.pin {
  width: 35px;
  height: 35px;
  color: #313131;
  border: 1px solid #313131;
  border-radius: 50% 50% 50% 0;
  background: #c19d5e;
  position: absolute;
  display: 'inline-flex';
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}