body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #313131
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*  */


/* administrator */

#view-admin .middleCard {  margin-bottom: 50px;}
#view-admin .card-body li{clear: both;}

#view-admin #spanSalir{top:0}

#view-admin .smallbutton , #view-admin .rightCard button{
  font-weight: 400;
  width: 150px;
  border-color: transparent !important;
  background-color: rgba(115,152,245,0.35) !important;
}

#view-admin #leftCardPedido button,#view-admin #rightCardPedido button
{font-size: 14px;
  font-weight: 400;
  width: 145px;
  border-color: transparent !important;
  background-color: rgba(115,152,245,0.35) !important;
  margin-top: 15px;;
}

.ModalOrderB {
    padding-right: 50px;
    padding-left: 50px;
}

#pedidoContent .card {    box-shadow: none;
    border: 0;
    background-color: transparent;}

.mapPedido{
margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
}
#pedidoContent{width: 100%;}

#leftCardPedido{margin-right: 100px;}

#rightCardPedido, #leftCardPedido{float:left;}

#view-admin #pedidoContent .btn-gold{    font-size: 14px;
    margin-top: 23px;
    height: 35px;
    line-height: 16px;}

#view-admin .card-body li{display: flex;}

#root #view-admin #administrator #contentShowProducts .card{margin-bottom:0;}

#contentShowProducts{margin-bottom:100px;}

#h4showProducts{margin-left: 40px;font-size: 16px;}

.middleCard{margin-top: 10px;
    margin-bottom: 10px;}

.leftMiddleCard{    float: left;
    margin-right: 20px;
    width: 180px;
}

#view-admin .card-body{font-size: 14px;}


.contentMap{    max-width: 200px;
    display: flow-root;}

#view-admin #login .card-body{max-width: 500px; margin: auto;    margin-top: 80px;}

li {
  list-style: none;
}

#admin > li::before {
  content: "\2022";
  color: #c19b57;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  
}


.leftCard{ margin-right:100px;}

.rightCard button{  float: left;
    width: 200px;
    font-size: 14px;
    margin-top: 10px;
    padding: 5px;}

.mapContainer div{height: 250px !important;}

.leftCard, .rightCard{float: left;
    width: 380px;
    
    font-size: 14px;
    padding: 10px;}

.leftCard li, .rightCard li{ margin-bottom: 8px;}

.card-title img{
    float: left;
    height: 35px;
    margin-right: 15px;
    margin-bottom: 10px;
}
#root #view-admin .sideTwo {    height: 700px;

    background-size: 100%;
}

#root #view-admin #administrator .card {

    min-width: 290px;
    margin: auto;
    box-shadow: none;
    margin-top: 40px;
    -webkit-box-shadow: 0px 7px 12px -1px rgba(0,0,0,0.39);
-moz-box-shadow: 0px 7px 12px -1px rgba(0,0,0,0.39);
box-shadow: 0px 7px 12px -1px rgba(0,0,0,0.39);

border-radius: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 40px;
    margin-bottom: 100px;
}



#root #administrator .form-control {
        padding: 9px 20px 9px 20px;
    border-color: transparent;
    height: 40px;
    display: -webkit-box;
}

#root #administrator [type=submit] {
    margin-top: 25px;
    font-size: 15px;
}

#root #administrator .col .btn-red {background-color:#FBC72D !important;}
.f12{font-size:12px;}

#administrator .row:first-child{margin-top:25px;}

#administrator .row .col .row:first-child{margin-top:0px;padding-left: 15px;}

#spanSalir{color: #aaa; font-weight: bold;position:relative;left:-25px;    top: 30px;}

.w3-display-right {

    /* top: 90px !important; */
    right: 25px !important;
 }   

#administrator .container .row .col{max-width: 100%;}

#administrator .container-title-menu{text-align: center;
    width: 100%;}

#administrator .container-title-menu img{margin: auto;
 
    margin-bottom: 15px;
    margin-top: 15px;
}

#administrator .navbar-admin{height: auto;    border-bottom: 5px solid #7398F5;}

#administrator{max-width: 1200px;margin: auto;    background-color: #f4f4f4;}

#administrator .navbar-admin{max-width: 1200px;margin:auto;}

.container .row .col{max-width: 400px;
    padding-left: 38px;
    padding-right: 38px;}


.navbar-nav li a img{    width: 30px;
    height: 30px;
    margin-right: 10px;}

.navbar-dark .navbar-toggler-icon{   
  background-size: 25px;
  border-radius: 5px;
    background-color: #cecece;
}

.container form{    margin-top: 25px;}


#listProduct .d-flex, #load .d-flex, #paths .d-flex, #order .d-flex{margin-top: 5px;}
/*
#listProduct [type=submit], #load [type=submit], #paths [type=submit], #order [type=submit]{    margin-top: -125px !important;}
*/
/*#listProduct .container{background-image: url(../images/gun.png);} */

#listProduct .container::after, #load .container::after, #order .container::after,
#listProduct .container::before, #load .container::before, #order .container::before

{
  content: "";
  background: url(../images/gun.png);
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
}




.title {
    font-size: 24px;
    font-weight: 900;
    padding-left: 20px;
}
#options .buttons-grid {
    padding: 10px;
        position: relative;
    top: 20px;
}

#options .btn-block{padding-top: 10px;
    padding-bottom: 10px;
   font-size: larger;
   font-weight: 900;
  }

#options .sideTwo
{    height: 520px !important;}


#root .card {
    border-radius: 0;
    box-shadow: none;
        /* margin-top: 40px; */
}

#root .sideTwo {
  /*  position: absolute;
    bottom: 0;*/
    padding-left: 20px;
    padding-right: 20px;
    height: 440px;
    background-repeat: no-repeat;
    background-position: bottom;
}

#root .form-control
{
     padding: 25px 20px 25px 20px;
         border-radius: 0;
     margin-bottom: 20px;
}

#root [type=submit]{margin-top: 40px;}
/*  */


#viewport-mobile {
  background-color: #f4f4f4;
  width: 100%;
  height: 100vh;
}
#viewport-web {
  background-color: #f4f4f4;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

/* sections */





#login, #options, #listProduct, #load, #paths, #order, #confirm{min-width: 360px;
    min-height: 600px;}

 #confirm .container{ min-height: 600px;    margin-top: -4px;
    background-repeat: no-repeat;
    background-position: bottom;
    height: auto !important; 
    }   


    #pegatina .imgover{
      margin-top: 50px;
      margin-bottom: 50px;
      top:0;
    }


#login, #options {
background-color: #fff;
width: 100%;
height: 100%;
}


/*
#listProduct, #confirm, #load, #order, #confirm, #administrator {
height: 100%;
}
*/

/* end*/
.centered {
  justify-content: center;
  align-items: center;
}
.sinbordefondo {
  background-color: #f4f4f4;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-style: dashed;
  text-align: center;
  outline: none;
}

.btn-block {
  display: block;
  font-weight: bolder;
  font-size: x-large;
  width: 100%;
  color: #fff;
  border-radius: 30px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.btn-block:hover {
  color: #fff;
}

.btn-gold {
  background-color: #FBC72D !important;
  border-color: #FBC72D !important;
}

.btn-red {
  background-color:#FBC72D !important;
  border-color: #FBC72D !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    max-width: 325px;
    margin: auto;


}
.btn-gray {
  background-color: #bfbfbf !important;
  border-color: #bfbfbf !important;
  font-size: large;
  padding-top: 4px;
  padding-bottom: 4px;
}
 .buttons-grid{
  width: 100%;
  padding: 9%;
}
.buttons-grid > button {
  margin-top: 25px;
  margin-bottom: 25px;
}


.input-read{
  margin: 200px 90px;
}

/* .container {
  height: 736px;
}
.button-confirm{
  margin: 250px 0px;
}
 #login {
  background-color: #1c171d;
  width: 100%;
  height: 100vh;
} */

.sideOne, .sideTwo {
  width: 100%;
}
.sideOne {


}
.loginform{

 padding-top: 40px;
}
#confirm > .container {
  background:linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(116,152,246,1) 0%, rgba(55,93,236,1) 100%);
  background-size: 100%;
  color: #fff;
  border-style: none;
}
#confirm > .container > p {
  text-align: center;
}
.sideTwo {

  background: rgb(2,0,36);
background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(116,152,246,1) 0%, rgba(55,93,236,1) 100%);
  background-size: 110%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.sideTop{
  /*
  height: 25%;
  position: relative;
  */
}
#pegatina {

  z-index: 999;
  text-align: center;

}

#pegatina-peq {

  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#pegatina-peq .imgover
{width: 130px;
top: 18px;
margin-bottom: 50px;
}

.imgover {
         width: 200px;
    margin-top: 50px;
    position: relative;
    top: 27px;
}

.card-body {
  background-color: transparent;
  border: 0px;
}
.wrapper {
  min-height: 100%;
  
  position:relative;
  margin-top: 56px;
}
.wrapper:after {
  content: "";
  display: block;
}
.container {
  flex: 2;
  height: calc(100% - 120px);
  display: block;
  overflow: auto;
}
.container-title-menu {

  text-align: center;
}
.footer, .wrapper:after {
 /* height: 60px;*/
  padding-right: 15px;
  padding-left: 15px;


}
.footer{ position: absolute;
    bottom: 100px;
    width: 100%;}

#order .footer, #load .footer, #listProduct .footer{ position:static !important; }

#order .footer [type=submit]{position: relative;    top: -10px; }
#load .footer [type=submit], #listProduct .footer [type=submit] {position: relative;    top: -5px; }
#load .sinbordefondo { position: relative; top: -20px}

#order{background-color: #f4f4f4;}

.smallbutton {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  top: 15px;
  width: 141px;
}

.smallbutton a { color: #0056b3; text-decoration: none;}
.smallbutton a:hover { text-decoration: underline;}

#administrator #contentShowProducts .row .col{max-width: 50%;}

#administrator .card-body>li>span , .mapPedido, #rightCardPedido, 
#rightCardPedido span, #leftCardPedido span{max-width: 400px;}

#administrator .btn-gold {
    height: 35px;
    line-height: 15px;
    font-size: 14px;
    position: relative;
    top: 23px;
}

.card {
  border-radius: 0.9rem;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2), 0 4px 20px 0 rgba(0,0,0,0.19);
  padding: 7px 18px;
  margin-top: 5px;
  margin-bottom: 6px;
}
.card-title{
  color: #c19b57;
  font-weight: bolder;
  border-bottom: 1px solid #bfbfbf;
}
.card-body {
  font-size: larger;
  padding: 0;
}
.card-body > li {
  color:#c19d5e;
}
.card-body > li > span{
  color:#313131;
  font-size: 14px;
 /* margin-left: -10px;*/
}


#card-content {
  width: 93%;
  font-size: larger;
  color: #585858;
}
#card-content > h4 {
  color: #c19b57;
  margin-bottom: 0px;
}
#card-content > h5 {
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 0px;
}
#card-content > h4 > label {
  margin-bottom: 0px;
}

span.icon {
  display: inline-block;
  vertical-align: middle;
  content: "";
  background-size: 100% 100%;
}
.delete {
  background-image: url(../images/trash.png);
  width: 1.5em;
  height: 1.5em;
}
.plus {
  background-image: url(../images/plus.png);
  width: 1.2rem !important;
  height: 1.2rem !important;
}
.refresh {
  background-image: url(../images/refresh.png);
  width: 1.2rem !important;
  height: 1.2rem !important;
  top: 33% !important;
  right: 5% !important;
}
.info {
  background-image: url(../images/info.png);
  margin-top: 5px;
  margin-right: 10px;  ;
  width: 1.1em;
  height: 1.1em;
}
.logout {
  background-image: url(../images/logout.png);
  width: 22px;
  height: 22px;
}

.w3-display-right {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translate(0%,-50%);
  -ms-transform: translate(0%,-50%);
}
.w3-display-left {
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translate(0%,-50%);
  -ms-transform: translate(0%,-50%);
}
.custom-control-label:before{

  background-color:#fff;
  top: 80%;
  transform: translate(0%,80%);
  -ms-transform: translate(0%,80%);
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before{
  background-color: #c19d5e;
  top: 80%;
  transform: translate(0%,80%);
  -ms-transform: translate(0%,80%);
}
.custom-control-label::after {
  top: 0.8rem;
}
custom-toggle-color{
  color: #585858;
}
.custom-checkbox .custom-control-input:checked~.custom-toggle-color::before{
  color: #585858;
}
.custom-checkbox .custom-control-input:checked~.custom-toggle-color::after{
  color: red;
}
.ModalOrderH {
  padding-bottom: 0px;
  color: #c19d5e;
}
.modal-header {
  padding-bottom: 0px;
  border-bottom: 0px;
}
.ModalOrderB {
  padding-top: 0px;
}

/* Menu */

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  font-size: large;
  font-weight: bold;
  color: #fff;
}
.navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active {
  color: #FBC72D;
}
.navbar-dark .navbar-nav .nav-link {
  color: white;
}

/* .navbar-default {
  padding-right: 8px;
} */
.navbar .navbar-toggle {
  border: none;
}
.navbar-collapse {
      padding: 0px 30px 5px 30px;
}
.navbar-nav .nav-item {
      text-align: left;
    padding-left: 5px;
  border-bottom: 1px solid #f0f0f0;
}
.navbar-nav .nav-item h3{font-weight: 800;}

/* .navbar .navbar-toggle:hover,
.navbar .navbar-toggle:focus {
  background: red;
} */

.navbar-header .navbar-toggle .icon-bar {
  background: #fff;
}
.navbar-header .navbar-toggle .icon-bar:hover {
  background: #fff;
}
.navbar-admin {
  height: 51px;
  padding: .5rem 1rem;
}
.side-collapse-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
/* @media screen and (max-width: 1090px) { */
  .side-collapse {
    top: 0px;
    bottom: 0;
    right: 0;
    width: 100%;
    position: fixed;
    transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(116,152,246,1) 0%, rgba(55,93,236,1) 100%);
  }
  .side-collapse.in {
    width: 0;
    background-color: transparent;
    opacity: 0.01;
  }
/*  .navbar-toggler-icon.in {
    transform: rotate(90deg) translateY(-100%);
    transform-origin:top left;
  }
}*/
a:not(:disabled) {
  cursor: pointer;
}
.menuCloseButton {
  text-align: right;
  padding-top: 15px;
  padding-right: 25px;
}
.icon-close{
  height: 20px;
  color: #fff;
}

.title {

  color: #9a9899;
  width: 100%;
  text-align: center;
}
.navbar-dark .navbar-toggler {
  border: 0px;;
}
.navbar-toggler {
  padding: 0px;
}
.listmodal {
  margin-top: 5px;
  margin-bottom: 6px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.close {
  font-size: 3rem;
  font-weight: lighter;
  opacity: .3;
}
.modal-header .close {
  padding: 0rem 0.5rem;
  margin: -1.5rem -1rem -1rem auto;
}
.modal-content {
  border-radius: 1rem;
}
.signatureCanvas{
  border: 1px dashed #ced4da;
  width: 100%;
  min-height: 200px;
  border-radius: 5px;
}


@media (min-width: 576px){
.modal-dialog {
    max-width: 100% !important;
    padding-right: 50px;
    padding-left: 50px;
}
}
